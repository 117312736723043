.site{
    position: relative;
    z-index: 0;
    padding-top: 80px;
    &.transparent{
        background: transparent;
    }
    .container{
        //max-width: 970px;
    }
    .breadcrumb{
        background: white;
    }
    .wrapper{
        background: transparent;
    }
    .bg-gray,
    .bg-grey{
        background: #eee;
    }
    blockquote{
        position: relative;
        //background: #eee;
        //padding: 15px 15px 15px 50px;
        padding-left: 40px;
        color: #aaa;
        line-height: 1;
        p{
            font-size: 17px !important;
            //font-style: italic;
            font-weight: 200 !important;
            line-height: 1.3;
            margin-bottom: 0;
        }
    }
    blockquote:before {
        display: block;
        content: "\201C";
        font-size: 80px;
        position: absolute;
        left: 0px;
        top: -10px;
        color: #aaa;
    }
    .quote-container{
        margin-top: 30px;
    }
    .card-description{
        padding-top: 10px;
        a{
            color: $info-color;
            text-decoration: underline;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            li {
                padding-left: 20px;
            }
            li:before {
                content: "\f111"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -20px; /* same as padding-left set on li */
                width: 20px; /* same as padding-left set on li */
                font-size: 8px;
                text-align: center;
                top: -2px;
                position: relative;
            }
        }
    }
    .navbar{
        box-shadow: none;
        .navbar-nav{
            .nav-item.active{
                .nav-link{
                    color: $info-color;
                }
            }
            .nav-item{
                .nav-link:hover{
                    color: $info-color;
                }
            }
        }
    }
    .navbar-brand{
        padding: 0;
        position: relative;
        height: 70px;
        @media (max-width: 991px) {
            left: 15px;
            font-size: 12px;
        }
        .logo{
            top: 0;
            position: absolute;
            max-height: 70px;
            @media (max-width: 575px) {
                left: 15px;
            }
        }
        .brand-text{
            display: inline-block;
            line-height: 1;
            padding-top: 15px;
        }
    }
    .navbar-toggler{
        @media (max-width: 575px) {
            position: relative;
            right: 15px;
        }
    }
    .logo_kleur{
        display: block;
    }
    .logo-wit{
        display: none;
    }
    .navbar-transparent{
        .logo_kleur{
            display: none;
        }
        .logo-wit{
            display: block;
        }
    }
    .header-wrapper{
        height: 550px;
    }
    .section{
        padding-top: 5px;
        padding-bottom: 30px;
        &.section-quote{
            padding-bottom: 10px;
        }
    }
    .section-home{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 0px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .section-with-breadcrumbs{
        padding-top: 5px;
    }
    .breadcrumb{
        margin-bottom: 0px;
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 59.25% 0 0 0;
        //margin-top: 20px;
        //margin-bottom: 20px;
        overflow: hidden;
        //border-radius: 12px;
        //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        .zoom-child{
            //border-radius: 12px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
}
@mixin theme-mixing($color) {
    .text-title{
        color: darken($color,20%) !important;
    }
    .list-group-item.active {
        background-color: lighten($color,10%);
        border-color: lighten($color,10%);
    }
    .dropdown-item{
        &.active{
            background-color: lighten($color,7%);
            color: white;
        }
    }
    .dropdown-item:hover{
        background-color: lighten($color,7%) !important;
        color: white !important;
    }
    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border: 2px solid $color;
      color: $color;
    }
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > a:active,
    .pagination > li.active > a,
    .pagination > li.active > span,
    .pagination > li.active > a:hover,
    .pagination > li.active > span:hover,
    .pagination > li.active > a:focus,
    .pagination > li.active > span:focus {
      background-color: $color;
      border-color: $color;
      color: #FFFFFF;
    }
    .section.section-page-color{
        background-color: lighten($brown-color, 7%);
        color: white;
        h3{
            margin-top: 20px;
        }
    }
}
body{
    &.color-default{
        @include theme-mixing($default-color);
    }
    &.color-primary{
        @include theme-mixing($primary-color);
    }
    &.color-info{
        @include theme-mixing($info-color);
    }
    &.color-success{
        @include theme-mixing($success-color);
    }
    &.color-warning{
        @include theme-mixing($warning-color);
    }
    &.color-danger{
        @include theme-mixing($danger-color);
    }
}
body{
    color: black;
    b, strong{
        //font-weight: 300;
    }
    .section-grey{
        background: #eee;
    }
    .section-white{
        background: white;
    }
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        //background-color: #f5593d;
        //border-color: #f5593d;
    }
    h1.title,
    h2.title,
    h3.title{
        font-size: 2.225em;
        line-height: 1.4;
        margin: 20px 0 15px;
        //margin-top: 50px;
        @media (max-width: 575px){
            font-size: 1.625em;
        }
        @media (max-width: 767px){
            //font-size: 2.325em;
        }
        @media (max-width: 991px){
            //font-size: 2.225em;
        }
    }
    h1.subtitle,
    h2.subtitle,
    h3.subtitle{
        font-size: 1.425em;
        line-height: 1.4;
        margin: 20px 0 20px;
    }
    .title-row{
        h1.title,
        h2.title,
        h3.title{
            margin-bottom: 40px;
        }
    }
    .title-news{
        margin-bottom: 30px !important;
    }
    .card{
        .card-description{
            margin-top: 0;
            .btn{
                margin-top: 20px;
            }
        }
    }
    .text-main{
        a,
        p{
            font-size: 15px;
            font-weight: 300;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 15px;
                font-weight: 300;
            }
            li {
                padding-left: 20px;
            }
            li:before {
                content: "\f111"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -20px; /* same as padding-left set on li */
                width: 20px; /* same as padding-left set on li */
                font-size: 8px;
                text-align: center;
                top: -2px;
                position: relative;
            }
        }
    }
    .intro{
        margin-bottom: 15px;
        p{
            font-size: 19px;
            font-weight: 400;
        }
        ul,ol{
            padding: 10px 0 10px;
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 19px;
            }
            li {
                padding-left: 20px;
            }
            li:before {
                content: "\f111"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -20px; /* same as padding-left set on li */
                width: 20px; /* same as padding-left set on li */
                font-size: 8px;
                text-align: center;
                top: -2px;
                position: relative;
            }
        }
        .home-intro-text{
            p{
                font-size: 19px;
                font-weight: 400;
            }
        }
        .home-normal-text{
            p{
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
    .text-intro{
        p{
            @media (min-width: 576px){
                margin-top: 0;
            }
            font-weight: bold;
        }
    }
    .home-intro{
        .section{
            padding-top: 50px;
            padding-bottom: 0px;
        }
        p{
            //font-size: 19px;
        }
    }
    .home-bullets{
        .section{
            background: #eee;
            padding-top: 30px;
            padding-bottom: 30px;
        }
        p{
            font-size: 15px;
        }
        ul,ol{
            padding: 10px 0 10px;
            @media (max-width: 767px){
                padding: 0;
            }
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 15px;
                font-weight: 300;
            }
            li {
                padding-left: 20px;
            }
            li:before {
                content: "\f111"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -20px; /* same as padding-left set on li */
                width: 20px; /* same as padding-left set on li */
                font-size: 8px;
                text-align: center;
                top: -2px;
                position: relative;
            }
        }
    }
    .footer{
        border-top: 1px solid #eee;
        padding-top: 15px;
        padding-bottom: 0;
        background: #fff;
        h4{
            font-size: 15px;
            margin: 15px 0 15px;
            color: black;
        }
        p{
            color: #333;
            font-size: 13px;
            font-weight: 200;
            a{
                font-size: 13px;
                font-weight: 200;
                color: #333;
                text-decoration: underline;
            }
        }
        ul li a{
            color: #333;
            font-size: 13px;
        }
        .footer-img{
            display: block;
            max-width: 100%;
            margin-bottom: 15px;
        }
        table{
            font-size: 13px;
            font-weight: 200;
            border: 0;
            td{
                border: 0;
                padding: 0;
                color: #333;
                font-size: 13px;
                font-weight: 200;
                line-height: 1.5;
            }
        }
        .sw_copyright{
            background: #eee;
            text-align: center;
            margin-top: 25px;
            p{
                padding: 20px 0;
                color: #333;
                font-size: 11px;
                font-weight: 200;
                margin: 0;
            }
        }
    }
    .home-contact{
        .image-landscape{
            display: block;
            position: relative;
            padding: 75% 0 0 0;
            //margin-top: 20px;
            //margin-bottom: 20px;
            overflow: hidden;
            margin-top: 10px;
        }
        h2.title{
            margin-top: 0;
            padding-top: 0;
        }
    }
    .slider-text{
        .slider-text-container{
            padding: 20px;
            background: rgba(255,255,255,.7);
            color: black;
            display: inline-block;
            @media (max-width: 575px) {
                padding: 10px;
            }
            .text-title{
                font-size: 24px;
                @media (max-width: 575px) {
                   font-size: 17px;
                }
            }
            .text-p{
                margin-top: 10px;
                font-size: 17px;
                font-weight: 200 !important;
                @media (max-width: 575px) {
                    font-size: 15px;
                }
            }
        }
    }
}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}