.after-parallax{
    position: relative;
    z-index: 1010;
}
.parallax-window{
    min-height: 50px;
    background: transparent;
    position: relative;
    z-index: 1009;
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}
.parallax-intro{
    background: rgba(0,0,0,.4);
    padding: 30px 30px;
    color: white;
    h2.title{
        color: white;
        margin: 0 0 10px;
        padding: 0;
    }
}